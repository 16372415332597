<template lang="pug">
  .booking-ticket-voucher-edit-card
    .card.h-100
      .card-header
        .float-left
          h5 Gutschein {{ bookingTicketVoucher.VoucherId }}
        .float-right(v-if="deleteBookingTicketVoucher")
          a(@click="deleteBookingTicketVoucher(bookingTicketVoucher)")
            font-awesome-icon.img-button(:icon="['fas', 'xmark']" size="xl")
      .card-body
        .row
          .col-12
            voucher-preview(
              v-if="voucher"
              :voucher="voucher"
            )
        .mt-3(v-if="bookingTicketVoucher.BookingTicketVoucherStatusId == BookingTicketVoucherStatusEnum.DatesCanBeBooked")
          .row
            .col-12
              hr
          .text-center
            .row
              .col-12
                h4 Gutscheincode:
            .row.mt-2
              .col-12
                voucher-code-input(
                  :check-voucher-code="checkVoucherCode"
                )

</template>

<script>
import VoucherPreview from "@/views/vouchers/VoucherPreview.vue";
import { VouchersComponent } from "@/lib/components/vouchers/VouchersComponent";
import VoucherCodeInput from "@/views/utils/voucherCode/VoucherCodeInput.vue";
import { BookingTicketVoucherStatusEnum } from "@/lib/enums/bookingTicketVoucherStatus/BookingTicketVoucherStatusEnum.ts";

export default {
    name: "BookingTicketVoucherEditCard",
    components: { VoucherCodeInput, VoucherPreview },
    props: {
        bookingTicketVoucher: {
            type: Object,
            required: true,
        },
        deleteBookingTicketVoucher: {
            type: Function,
            required: false,
        },
    },
    data() {
        return {
            voucher: undefined,
            BookingTicketVoucherStatusEnum: BookingTicketVoucherStatusEnum,
        };
    },
    async mounted() {
        try {
            this.loadVoucher();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadVoucher() {
            try {
                const vouchersComponent = new VouchersComponent();
                const voucher = await vouchersComponent.getVoucher(
                    this.$apollo,
                    this.bookingTicketVoucher?.VoucherId
                );
                if (!voucher) {
                    return;
                }
                this.voucher = voucher;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        checkVoucherCode(code) {
            try {
                if (code?.length < 6) {
                    return;
                }
                if (
                    this.voucher?.Guid.slice(-6).toLowerCase() !=
                    code.toLowerCase()
                ) {
                    return {
                        IsSuccess: false,
                        Message: "Der Code stimmt nicht überein.",
                    };
                }
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>
