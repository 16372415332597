<template lang="pug">
  .container-fluid.mt-3
    .row
      .col-12
        .section-headline Gutscheindaten

    .row
      .col-12.col-lg-4
        .form-group
          label(for='VoucherProceed').form-label {{ $t("voucher.detail.voucherProceed") }}
          span(v-if="!item") lädt...
          template(v-else)
            input.form-control.custom-control(type='text', :value="item.SingleItemPriceGross - item.InsuranceAmount | formatEuro", name="PurchasePrice", :placeholder='$t("voucher.detail.voucherProceed")', disabled="true" v-if="!voucher.IsSelfSale")
          input#VoucherProceed.form-control.custom-control(type='text', value="touriDat-Gutschein Werbemaßnahme", name="PurchasePrice", :placeholder='$t("voucher.detail.voucherProceed")', disabled="true" v-else)
      .col-12.col-lg-4
        .form-group
          label(for='ArticleNumber').form-label {{ $t("voucher.detail.productNumber") }}
          input#ArticleNumber.form-control.custom-control(type='text' :value="voucher.ProductId" :placeholder='$t("voucher.detail.productNumber")' disabled)
      .col-12.col-lg-4
        .form-group
          label(for='paidAt').form-label {{ $t("voucher.detail.paidOn") }}
          input#paidAt.form-control.custom-control(type='text', :value="order.PaidAt | formatDateTime", name="paidAt", :placeholder='$t("voucher.detail.paidOn")', disabled)

    .row
      .col-12.col-lg-6
        .form-group
          label.form-label {{ $t("voucher.detail.payoutDate") }}
          input.form-control.custom-control(type='text', :value="voucher.PayoutDate | formatDateTime", :placeholder='$t("voucher.detail.payoutDate")', disabled v-if="voucher")
      .col-12.col-lg-6
        .form-group
          label.form-label {{$t("voucher.detail.validTill")}}
          input.form-control.custom-control(type='text', :value="voucher.ValidTill | formatDateTime" :placeholder='$t("voucher.detail.validTill")', disabled v-if="voucher")

    .row
      .col-12.col-lg-6
        .form-group
          label.form-label {{ $t("voucher.detail.hotelServices") }}
          textarea-autosize.form-control(disabled="true", v-model="voucher.HolidayServices")
      .col-12.col-lg-6
        .form-group
          label.form-label {{ $t("voucher.detail.touristicalServices") }}
          textarea-autosize.form-control(disabled="true" v-model="voucher.AdditionalHolidayServices")

    .row
      .col-12.col-lg-6
        .form-group
          label.form-label {{ $t("voucher.detail.extraInfo") }}
          textarea-autosize.form-control(disabled="true", v-model="voucher.AdditionalInfo")
      .col-12.col-lg-6
        .form-group
          label.form-label {{ $t("voucher.detail.bookability") }}
          textarea-autosize.form-control(disabled="true", v-model="voucher.BookingAvailability")

    .template(v-if="bookingTickets && Array.isArray(bookingTickets) && bookingTickets.length")
      .row
        .col-12
          h5 Buchungen
      .row
        .col-12
          booking-ticket-panel(
            v-for="bookingTicket in bookingTickets"
            :key="bookingTicket.Id"
            :booking-ticket-id="bookingTicket.Id"
            :host-ids="[hostId]"
            :class="{ 'mt-3': bookingTickets.indexOf(bookingTicket) }"
          )

    .row
      .col-12.mt-3
        .float-right
          router-link.button.button-primary.button-tdays(:to="{ name: 'VouchersList' }") {{ $t("constants.buttons.back") }}

</template>

<script>
import { BookingTicketStatusEnum } from "../../../lib/enums/bookingTicketStatus/BookingTicketStatusEnum";
import VoucherStatusEnum from "../../../lib/enums/voucherStatus/VoucherStatusEnum";
import { VouchersComponent } from "@/lib/components/vouchers/VouchersComponent";
import OrdersComponent from "@/lib/components/orders/OrdersComponent";
import EventBus from "@/views/event-bus";
import { ItemsComponent } from "@/lib/components/items/ItemsComponent";
import BookingTicketsComponent from "@/lib/components/bookingTickets/BookingTicketsComponent";
import BookingTicketPanel from "@/views/bookingTickets/BookingTicketPanel.vue";

const moment = require("moment");

export default {
    name: "VoucherDetails",
    components: { BookingTicketPanel },
    props: {
        voucherGuid: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            voucher: {},
            order: {},
            item: {},
            bookingTickets: [],
            hostId: this.$session.get("hostId"),

            BookingTicketStatusEnum: BookingTicketStatusEnum,
            VoucherStatusEnum: VoucherStatusEnum,
            moment: moment,
        };
    },
    async mounted() {
        try {
            this.loadVoucher();
            EventBus.$on(
                "changeHostId",
                function (hostId) {
                    this.hostId = hostId;
                }.bind(this)
            );
        } catch (e) {
            console.log(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadVoucher() {
            try {
                const vouchersComponent = new VouchersComponent();
                EventBus.$emit("changeLoadingState", true);
                const voucher = await vouchersComponent.getVoucher(
                    this.$apollo,
                    undefined,
                    this.voucherGuid
                );
                EventBus.$emit("changeLoadingState", false);
                if (!voucher) {
                    return this.$alert(
                        "Es konnte kein Gutschein geladen werden."
                    );
                }
                this.voucher = voucher;
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async loadOrder() {
            try {
                const ordersComponent = new OrdersComponent();
                const order = await ordersComponent.getOrder(
                    this.$apollo,
                    undefined,
                    this.voucher?.Id
                );
                if (!order) {
                    return;
                }
                this.order = order;
            } catch (e) {
                console.log(e);
            }
        },
        async loadItem() {
            try {
                const itemsComponent = new ItemsComponent();
                const item = await itemsComponent.getItem(
                    this.$apollo,
                    undefined,
                    this.voucher?.Id
                );
                if (!item) {
                    return;
                }
                this.item = item;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadBookingTickets() {
            try {
                const bookingTicketsComponent = new BookingTicketsComponent();
                const bookingTickets =
                    await bookingTicketsComponent.getBookingTickets(
                        this.$apollo,
                        [this.hostId],
                        this.voucher?.Id
                    );
                if (!bookingTickets) {
                    return;
                }
                this.bookingTickets = bookingTickets;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    watch: {
        voucher() {
            try {
                if (!this.voucher) {
                    return;
                }
                this.loadOrder();
                this.loadItem();
                this.loadBookingTickets();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>
